import React from 'react';
import Lottie from 'react-lottie';
import animationData from './../animation/refer.json'; // Your Lottie JSON file
import { Col, Row } from 'react-bootstrap';
import { FiCopy } from 'react-icons/fi';
import './Refer.css';
import { useDispatch, useSelector } from "react-redux";
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import Change from '../../Common/StringToSub';

const Refer = ({ username }) => {
    const CopyFromtag = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                toastSuccess("Referral link copied to clipboard!");
            },
            (err) => {
                toastFailed("Failed to copy: ", err);
            }
        );
    };

    const profileData = useSelector((state) => state?.dashboardData?.profileData);
    // BasicInfo.isDebug && console.log(profileData, "Profile Data");

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="dashboardReferSection">
            <Row className="w-100 justify-content-center align-items-center dashboardReferSectionDataCntnr" >
                <Col xs={12} md={6} className="d-flex justify-content-center">
                    <div>
                        <Lottie
                            options={defaultOptions}
                            height={100}  // Responsive Lottie size
                            width={300}   // Responsive Lottie size
                        />
                    </div>
                </Col>
                <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-center">
                    <h1>Your Referral Link</h1>
                    <div className="headerLogoLink headerLinkHeader">
                        <div className="headerLinkDiv">
                            <div className="linktext d-flex align-items-center" style={{ display: "flex", alignItems: "center", gap: "10px", color: "#fff" }}>
                                <h2
                                    id="headerLink1"
                                    className="refer-link"
                                >
                                    {window.location.origin + "/register?ref=" + Change(profileData?.wallet_address)}
                                    <i onClick={() => CopyFromtag(`${window.location.origin}/register?ref=${profileData?.wallet_address}`)}>
                                        <FiCopy />
                                    </i>
                                </h2>

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Refer;
