import React, { useState } from 'react'
import IncomeOverview from "./../images/iconss/income-overview.webp"
import Packege from "./../images/iconss/packege.webp"
import Genealogy from "./../images/iconss/genealogy.webp"
import Withdraws from "./../images/iconss/withdraw.webp"
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { Link } from 'react-router-dom'
import Popup from './DropdownMenu'

export default function Footer() {


    const [dropDown, setDropDown] = useState(false);

    const handleDropDown = () => {
        setDropDown(!dropDown);
    };



    return (
        <>
            <div className="bottom-menu fixed -bottom-1 left-0 md:left-1/2 md:transform md:-translate-x-1/2 w-full md:w-3/4 lg:w-1/2 py-4 px-5 sm:px-8 z-40 md:mx-auto rounded-2xl drop-shadow-[0_-35px_35px_rgba(255,255,255,0.05)] z-50">
                <div className="grid grid-cols-5 gap-3">
                    <div className="col-span-1">
                        <Link className="nav-link flex flex-col items-center justify-center text-gray-200 hover:text-primary font-normal text-center" to="/IncomeOverview?tab=dailyBonus">
                            <img src={IncomeOverview} width={32} height={32} className="h-8 w-8 max-h-8 max-w-8 logo-glow" alt="income-overview" />
                            <span className="text-[10px]">Income Overview</span>
                        </Link>
                    </div>
                    <div className="col-span-1">
                        <Link to="/Packages" className="nav-link flex flex-col items-center justify-center text-gray-200 hover:text-primary font-normal text-center">
                            <img src={Packege} width={32} height={32} className="h-8 w-8 max-h-8 max-w-8 logo-glow" alt="packege" />
                            <span className="text-[10px]">Package</span>
                        </Link>
                    </div>
                    <div className="col-span-1">
                        <div className="dropdownmenu">
                            {/* Dropdown menu */}

                            {/* <div id="dropdownSearch" className="dropdown-bar hidden z-10 w-auto bg-[#000000c7] rounded-2xl divide-y divide-gray-100 shadow" style={{ backdropFilter: 'blur(50px)', position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(306px, 47px)' }} data-popper-placement="bottom">
                                <ul className="p-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                                    <li>
                                        <Link to="/MyDirects" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                            <img src={MyDirects} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="my-direct" />
                                            <span className="ml-2 text-sm font-medium text-cyan-300">My Directs</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/MyTeam" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                            <img src={MyTeam} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="my-team" />
                                            <span className="ml-2 text-sm font-medium text-cyan-300">My Team</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Help" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                            <img src={Help} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="help" />
                                            <span className="ml-2 text-sm font-medium text-cyan-300">Help</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Materials" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                            <img src={Material} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 ml-1 logo-glow" alt="materials" />
                                            <span className="ml-2 text-sm font-medium text-cyan-300">Materials</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}

                            <button onClick={handleDropDown} id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" className="dropdown-btn bg-[#00ffff] hover:bg-gray-200 ring-2 ring-[#50dbff] ring-opacity-80 rounded-full -mt-8 text-center w-16 h-16 mx-auto flex items-center justify-center text-3xl text-black hover:text-black shadow-lg relative transition-all duration-500">
                                {dropDown ? <LiaTimesSolid /> : <LiaNetworkWiredSolid />}
                            </button>
                            {dropDown && <Popup closePopup={handleDropDown} />}
                        </div>
                    </div>
                    <div className="col-span-1">
                        <Link className="nav-link flex flex-col items-center justify-center text-gray-200 hover:text-primary font-normal text-center" to="/Genealogy">
                            {/* <i class="las la-link text-xl lg:text-2xl mb-0"></i> */}
                            <img src={Genealogy} width={32} height={32} className="h-8 w-8 max-h-8 max-w-8  logo-glow" alt="genealogy" />
                            <span className="text-[10px]">Genealogy Tree</span>
                        </Link>
                    </div>
                    <div className="col-span-1">
                        <Link className="nav-link flex flex-col items-center justify-center text-gray-200 hover:text-primary font-normal text-center" to="/Withdraws">
                            <img src={Withdraws} width={32} height={32} className="h-8 w-8 max-h-8 max-w-8 logo-glow" alt="" />
                            <span className="text-[10px]">Withdraw</span>
                        </Link>
                    </div>
                </div>
            </div >
        </>)
}
