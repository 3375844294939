import { configureStore } from "@reduxjs/toolkit";
import SideDisplaySlice from "./../Redux/SideDisplaySlice";
import dashboardDataSlice from "./../Redux/DashboardDataSlice";
import Accounts from "./Accounts";
import rateSlice from "./rateSlice";

export default configureStore({
  reducer: {
    sideDisplay: SideDisplaySlice,
    account:Accounts,
    dashboardData:dashboardDataSlice,
    rate: rateSlice,
  },
});
