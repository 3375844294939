import React from 'react'
import { Link } from 'react-router-dom'
import MyDirects from "./../images/iconss/my-direct.webp"
import MyTeam from "./../images/iconss/my-team.webp"
import Material from "./../images/iconss/materials.webp"
import Help from "./../images/iconss/help.webp"
import './DropdownMenu.css'

export default function DropdownMenu() {
        return (
        <div className="popup">
            <div className="popup-inner">
                {/* <div id="dropdownSearch" className="dropdown-bar hidden z-10 w-auto bg-[#000000c7] rounded-2xl divide-y divide-gray-100 shadow" style={{ backdropFilter: 'blur(50px)', position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(306px, 47px)' }} data-popper-placement="bottom"> */}
                    <ul className="p-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownTopButton">
                        <li>
                            <Link to="/MyDirects" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                {/* <i class="las la-project-diagram text-xl text-[#50dbff]"></i> */}
                                <img src={MyDirects} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="my-direct" />
                                <span className="ml-2 text-sm font-medium text-cyan-300">My Directs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/MyTeam" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                {/* <i class="las la-sitemap text-xl text-[#50dbff]"></i> */}
                                <img src={MyTeam} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="my-team" />
                                <span className="ml-2 text-sm font-medium text-cyan-300">My Team</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Help" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                {/* <i class="las la-vote-yea text-xl text-[#50dbff]"></i> */}
                                <img src={Help} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 logo-glow" alt="help" />
                                <span className="ml-2 text-sm font-medium text-cyan-300">Help</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Material" className="flex items-center w-full px-6 py-2 mt-2 rounded hover:bg-[#ffffff4a] text-white hover:text-gray-300 whitespace-pre">
                                {/* <i class="las la-wallet text-xl text-[#50dbff]"></i> */}
                                <img src={Material} width={65} height={65} className="h-6 w-auto max-h-6 max-w-8 ml-1 logo-glow" alt="materials" />
                                <span className="ml-2 text-sm font-medium text-cyan-300">Materials</span>
                            </Link>
                        </li>
                    </ul>
                {/* </div> */}
            </div>
        </div>
    );
};

