import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
import tokenABI from "../Contracts/Token_ABI.json";

const arr = {
  contract: "0xb41a89D44379c63A67B1F4a052cBeC1983F49055",
  contractABI: contractABI,
  BUSD: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  BUSD_ABI: BUSD_ABI,
  token: "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F",
  tokenABI: tokenABI,
};

export const ContractDetails = arr;

// Action creators are generated for each case reducer function

export default ContractDetails;
