// import React from 'react';
// import { Pagination } from 'react-bootstrap';
// import './PaginationControls.css';
// import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
// const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
//     const handlePagination = (page) => {
//         onPageChange(page);
//     };

//     const handlePrevious = () => {
//         if (currentPage > 1) {
//             onPageChange(currentPage - 1);
//         }
//     };

//     const handleNext = () => {
//         if (currentPage < totalPages) {
//             onPageChange(currentPage + 1);
//         }
//     };

//     const generatePageNumbers = () => {
//         const pageNumbers = [];
//         const maxVisiblePages = 5; // Number of pages to show at a time
//         const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
//         let startPage = Math.max(1, currentPage - halfMaxVisiblePages);
//         let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

//         if (endPage - startPage < maxVisiblePages - 1) {
//             startPage = Math.max(1, endPage - maxVisiblePages + 1);
//         }

//         if (startPage > 1) {
//             pageNumbers.push(
//                 <Pagination.First key="first" onClick={() => handlePagination(1)} />
//             );
//             pageNumbers.push(
//                 <Pagination.Prev key="prev" onClick={handlePrevious} />
//             );
//         }

//         for (let i = startPage; i <= endPage; i++) {
//             pageNumbers.push(
//                 <Pagination.Item
//                     key={i}
//                     active={i === currentPage}
//                     onClick={() => handlePagination(i)}
//                 >
//                     {i}
//                 </Pagination.Item>
//             );
//         }

//         if (endPage < totalPages) {
//             pageNumbers.push(
//                 <Pagination.Next key="next" onClick={handleNext} />
//             );
//             pageNumbers.push(
//                 <Pagination.Last key="last" onClick={() => handlePagination(totalPages)} />
//             );
//         }

//         return pageNumbers;
//     };

//     return (
//         <Pagination className="justify-content-center mt-4">
//             <Pagination.Prev onClick={handlePrevious} id='paginationButton'><i><FaAngleLeft /></i></Pagination.Prev>
//             {generatePageNumbers()}
//             <Pagination.Next onClick={handleNext} id='paginationButton'><i><FaAngleRight /></i></Pagination.Next>
//         </Pagination>
//     );
// };

// export default PaginationComponent;


// import React, { useState } from 'react';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//     const [inputPage, setInputPage] = useState(currentPage);

//     const handlePrevious = () => {
//         if (currentPage > 1) {
//             onPageChange(currentPage - 1);
//             setInputPage(currentPage - 1);
//         }
//     };

//     const handleNext = () => {
//         if (currentPage < totalPages) {
//             onPageChange(currentPage + 1);
//             setInputPage(currentPage + 1);
//         }
//     };

//     const handleInputChange = (e) => {
//         const value = e.target.value;
//         if (!isNaN(value)) {
//             setInputPage(value);
//         }
//     };

//     const handlePageSubmit = () => {
//         const pageNumber = Math.min(Math.max(1, parseInt(inputPage)), totalPages);
//         onPageChange(pageNumber);
//     };

//     const handleEnterKey = (e) => {
//         if (e.key === 'Enter') {
//             handlePageSubmit();
//         }
//     };

//     return (
//         <div className="flex justify-around items-center mt-4">
//             <div className="flex justify-around items-center mt-4 gap-5">
//                 <button
//                     className="px-4 py-2 bg-gray-700 text-white rounded"
//                     onClick={handlePrevious}
//                     disabled={currentPage === 1}
//                 >
//                     <i><FaAngleLeft /></i>
//                 </button>

//                 <span className="text-white">
//                     Page {currentPage} of {totalPages}
//                 </span>



//                 <button
//                     className="px-4 py-2 bg-gray-700 text-white rounded"
//                     onClick={handleNext}
//                     disabled={currentPage === totalPages}
//                 >
//                     <i><FaAngleRight /></i>
//                 </button>

//             </div>
//             <div className="flex justify-around items-center mt-4 gap-5 ">
//                 <input
//                     type="number"
//                     className=" bg-[#000000] rounded-md text-center text-white border border-[#006f76] p-2 "
//                     value={inputPage}
//                     onChange={handleInputChange}
//                     onKeyDown={handleEnterKey}
//                     min="1"
//                     max={totalPages}
//                 />

//                 <button
//                     className="px-4 py-2 bg-gray-700 text-white rounded"
//                     onClick={handlePageSubmit}
//                 >
//                     Go
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default Pagination;



import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [inputPage, setInputPage] = useState(currentPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
            setInputPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
            setInputPage(currentPage + 1);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) {
            setInputPage(value);
        }
    };

    const handlePageSubmit = () => {
        const pageNumber = Math.min(Math.max(1, parseInt(inputPage)), totalPages);
        onPageChange(pageNumber);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handlePageSubmit();
        }
    };

    return (
        <div className="flex flex-col md:flex-row justify-center items-center mt-4 gap-4">
            {/* Pagination controls */}
            <div className="flex items-center gap-2">
                <button
                    className="px-3 py-2 bg-gray-700 text-white rounded disabled:opacity-50"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                >
                    <i><FaAngleLeft /></i>
                </button>

                <span className="text-white text-sm md:text-base">
                    Page {currentPage} of {totalPages}
                </span>

                <button
                    className="px-3 py-2 bg-gray-700 text-white rounded disabled:opacity-50"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                >
                    <i><FaAngleRight /></i>
                </button>
            </div>

            {/* Input for direct page number */}
            <div className="flex items-center gap-2">
                <input
                    type="number"
                    className="w-16 md:w-20 bg-black rounded-md text-center text-white border border-teal-600 p-2 text-sm md:text-base"
                    value={inputPage}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKey}
                    min="1"
                    max={totalPages}
                />

                <button
                    className="px-3 py-2 bg-gray-700 text-white rounded"
                    onClick={handlePageSubmit}
                >
                    Go
                </button>
            </div>
        </div>
    );
};

export default Pagination;
