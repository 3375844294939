import { createSlice } from '@reduxjs/toolkit'

export const Accounts = createSlice({
  name: 'account',
  initialState: {
    value: null,
    authToken: null,
  },
  reducers: {     
    setaccount: (state, action) => {
      state.value = action.payload
    },
     setAuthToken: (state, action) => {
      state.authToken = action.payload; // Corrected field name to authToken
    },
  },
})

// Action creators are generated for each case reducer function
export const { setaccount,setAuthToken } = Accounts.actions

export default Accounts.reducer