import { BasicInfo, toastFailed } from "../Config/BasicInfo";
import ContractDetails from "../Contracts/ContractDetails";
import { ethers } from 'ethers';
export default async function TokenLiveRate() {
        try {
            const { ethereum } = window;
            if (true) {
                // const provider = new ethers.providers.Web3Provider(ethereum);
                const provider = new ethers.providers.JsonRpcProvider(
                    BasicInfo.providerLink
                  );
                const signer = provider.getSigner();

                const busdInstance = new ethers.Contract(
                    ContractDetails.contract,
                    ContractDetails.contractABI,
                    // signer
                    provider
                );
                const rate = await busdInstance.rate();
                console.log(rate/1e18,"rate")
                return rate/1e18;
            }
        } catch (error) {
            BasicInfo.isDebug && console.log("Live Rate error11", error);
            return 0;
        }
    }