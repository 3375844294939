import React from 'react'
import './Materials.css'



import Header from '../../Components/Header'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer'
export default function Material() {
  return (
    <div>
    <div id="mobile" className="demo2 relative">
      <div id="mobileBodyContent">
        <Header/>
        {/*  */}
        <div className="inner-content px-4 2xl:px-3 mx-auto mt-5 pb-20">
          <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
            <div className="container mx-auto overflow-hidden">
              <div className="flex flex-wrap">
                <div className="contentBox relative w-full">
                  <div className="mb-8 p-2 md:p-6 rounded-sm bg-stone-700 bg-opacity-10 border border-[#006f76] relative shadow-inner relative">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute -bottom-[25%] sm:-bottom-[23%] -left-[45px] h-[200px] filter-light" xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                      <defs>
                        <style dangerouslySetInnerHTML={{__html: "\n                                                .cls-1 {\n                                                    fill: #006f76;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    fill: #01ffff;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: none;\n                                                }\n\n                                                .cls-4 {\n                                                    clip-path: url(#clippath-1);\n                                                }\n\n                                                .cls-5 {\n                                                    clip-path: url(#clippath);\n                                                }\n                                            " }} />
                        <clipPath id="clippath">
                          <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                        </clipPath>
                        <clipPath id="clippath-1">
                          <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                        </clipPath>
                      </defs>
                      <g id="Layer_2-2" data-name="Layer 2">
                        <g className="cls-5">
                          <g className="cls-4">
                            <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                            <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                            <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                            <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-[25%] sm:-top-[23%] -right-[45px] h-[200px] filter-light" style={{transform: 'rotate(180deg)'}} xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                      <defs>
                        <style dangerouslySetInnerHTML={{__html: "\n                                                .cls-1 {\n                                                    fill: #006f76;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    fill: #01ffff;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: none;\n                                                }\n\n                                                .cls-4 {\n                                                    clip-path: url(#clippath-1);\n                                                }\n\n                                                .cls-5 {\n                                                    clip-path: url(#clippath);\n                                                }\n                                            " }} />
                        <clipPath id="clippath">
                          <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                        </clipPath>
                        <clipPath id="clippath-1">
                          <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                        </clipPath>
                      </defs>
                      <g id="Layer_2-2" data-name="Layer 2">
                        <g className="cls-5">
                          <g className="cls-4">
                            <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                            <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                            <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                            <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div className="title text-lg font-medium text-white mb-5 tracking-wider textdefibold">
                      Materials</div>
                    <div className="flex flex-wrap gap-5 justify-center pb-5 w-full">
                      <div className="rounded-md relative mb-2 px-3 py-1 sm:p-1  rounded-md w-[19rem] h-auto my-4 shadow-xl flex justify-between items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow -z-[1]">
                          <defs>
                            <style dangerouslySetInnerHTML={{__html: "\n                                                        .cls-1 {\n                                                            opacity: .24;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2 {\n                                                            fill: #29abe2;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2,\n                                                        .cls-3 {\n                                                            stroke-width: 0px;\n                                                        }\n\n                                                        .cls-2 {\n                                                            opacity: .29;\n                                                        }\n\n                                                        .cls-3 {\n                                                            fill: aqua;\n                                                        }\n                                                    " }} />
                          </defs>
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <g>
                                <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                              </g>
                            </g>
                            <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                            <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                            <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                            <g>
                              <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                              <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                              <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                              <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                              <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                              <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                            </g>
                            <g>
                              <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                              <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                              <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                              <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                              <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                              <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                            </g>
                            <g>
                              <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <g>
                              <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                          </g>
                        </svg>
                        <div className="text-white flex items-center  -mt-[0.3rem] sm:mt-0.5">
                          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[2.8rem] w-[2.8rem]  fill-[#07eceb8a]">
                            <g id="Media / Image_02">
                              <path id="Vector" d="M3.00005 18.0001C3 17.9355 3 17.8689 3 17.8002V6.2002C3 5.08009 3 4.51962 3.21799 4.0918C3.40973 3.71547 3.71547 3.40973 4.0918 3.21799C4.51962 3 5.08009 3 6.2002 3H17.8002C18.9203 3 19.4801 3 19.9079 3.21799C20.2842 3.40973 20.5905 3.71547 20.7822 4.0918C21 4.5192 21 5.07899 21 6.19691V17.8031C21 18.2881 21 18.6679 20.9822 18.9774M3.00005 18.0001C3.00082 18.9884 3.01337 19.5058 3.21799 19.9074C3.40973 20.2837 3.71547 20.5905 4.0918 20.7822C4.5192 21 5.07899 21 6.19691 21H17.8036C18.9215 21 19.4805 21 19.9079 20.7822C20.2842 20.5905 20.5905 20.2837 20.7822 19.9074C20.9055 19.6654 20.959 19.3813 20.9822 18.9774M3.00005 18.0001L7.76798 12.4375L7.76939 12.436C8.19227 11.9426 8.40406 11.6955 8.65527 11.6064C8.87594 11.5282 9.11686 11.53 9.33643 11.6113C9.58664 11.704 9.79506 11.9539 10.2119 12.4541L12.8831 15.6595C13.269 16.1226 13.463 16.3554 13.6986 16.4489C13.9065 16.5313 14.1357 16.5406 14.3501 16.4773C14.5942 16.4053 14.8091 16.1904 15.2388 15.7607L15.7358 15.2637C16.1733 14.8262 16.3921 14.6076 16.6397 14.5361C16.8571 14.4734 17.0896 14.4869 17.2988 14.5732C17.537 14.6716 17.7302 14.9124 18.1167 15.3955L20.9822 18.9774M20.9822 18.9774L21 18.9996M15 9C14.4477 9 14 8.55228 14 8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9Z" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </div>
                        <div className="pr-5">
                          <p className="text-xl capitalize font-semibold pt-4">album</p>
                          <Link 
                          // to="https://defiworld.ai/download-materials?material_type=album"
                          >
                            <div className="flex space-x-2 text-gray-400 text-sm mt-1">
                              {/* svg  */}
                              <svg width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="#8899a4" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5C8.24261 5 5.43602 7.4404 3.76737 9.43934C2.51521 10.9394 2.51521 13.0606 3.76737 14.5607C5.43602 16.5596 8.24261 19 12 19C15.7574 19 18.564 16.5596 20.2326 14.5607C21.4848 13.0606 21.4848 10.9394 20.2326 9.43934C18.564 7.4404 15.7574 5 12 5Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <p>View</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="rounded-md relative mb-2 px-3 py-1 sm:p-1  rounded-md w-[19rem] h-auto my-4 shadow-xl flex justify-between items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow -z-[1]">
                          <defs>
                            <style dangerouslySetInnerHTML={{__html: "\n                                                        .cls-1 {\n                                                            opacity: .24;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2 {\n                                                            fill: #29abe2;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2,\n                                                        .cls-3 {\n                                                            stroke-width: 0px;\n                                                        }\n\n                                                        .cls-2 {\n                                                            opacity: .29;\n                                                        }\n\n                                                        .cls-3 {\n                                                            fill: aqua;\n                                                        }\n                                                    " }} />
                          </defs>
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <g>
                                <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                              </g>
                            </g>
                            <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                            <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                            <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                            <g>
                              <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                              <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                              <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                              <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                              <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                              <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                            </g>
                            <g>
                              <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                              <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                              <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                              <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                              <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                              <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                            </g>
                            <g>
                              <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <g>
                              <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                          </g>
                        </svg>
                        <div className="text-white flex items-center  -mt-[0.3rem] sm:mt-0.5">
                          {/* svg  */}
                          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[2.8rem] w-[2.8rem]  fill-[#07eceb8a]">
                            <circle cx={12} cy={12} r={10} stroke="#ffffff" strokeWidth={2} strokeLinecap="round" />
                            <path d="M7.88124 15.7559C8.37391 16.1826 9.02309 16.4909 9.72265 16.6928C10.4301 16.897 11.2142 17 12 17C12.7858 17 13.5699 16.897 14.2774 16.6928C14.9769 16.4909 15.6261 16.1826 16.1188 15.7559" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" />
                            <circle cx={9} cy={10} r="1.25" fill="#ffffff" stroke="#ffffff" strokeWidth="0.5" strokeLinecap="round" />
                            <circle cx={15} cy={10} r="1.25" fill="#ffffff" stroke="#ffffff" strokeWidth="0.5" strokeLinecap="round" />
                          </svg>
                        </div>
                        <div className="pr-5">
                          <p className="text-xl capitalize font-semibold pt-4">logo</p>
                          <Link 
                          // to="https://defiworld.ai/download-materials?material_type=logo"
                          >
                            <div className="flex space-x-2 text-gray-400 text-sm mt-1">
                              {/* svg  */}
                              <svg width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="#8899a4" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5C8.24261 5 5.43602 7.4404 3.76737 9.43934C2.51521 10.9394 2.51521 13.0606 3.76737 14.5607C5.43602 16.5596 8.24261 19 12 19C15.7574 19 18.564 16.5596 20.2326 14.5607C21.4848 13.0606 21.4848 10.9394 20.2326 9.43934C18.564 7.4404 15.7574 5 12 5Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <p>View</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="rounded-md relative mb-2 px-3 py-1 sm:p-1  rounded-md w-[19rem] h-auto my-4 shadow-xl flex justify-between items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow -z-[1]">
                          <defs>
                            <style dangerouslySetInnerHTML={{__html: "\n                                                        .cls-1 {\n                                                            opacity: .24;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2 {\n                                                            fill: #29abe2;\n                                                        }\n\n                                                        .cls-1,\n                                                        .cls-2,\n                                                        .cls-3 {\n                                                            stroke-width: 0px;\n                                                        }\n\n                                                        .cls-2 {\n                                                            opacity: .29;\n                                                        }\n\n                                                        .cls-3 {\n                                                            fill: aqua;\n                                                        }\n                                                    " }} />
                          </defs>
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                              <g>
                                <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                              </g>
                            </g>
                            <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                            <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                            <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                            <g>
                              <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                              <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                              <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                              <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                              <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                              <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                            </g>
                            <g>
                              <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                              <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                              <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                              <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                              <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                              <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                            </g>
                            <g>
                              <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <g>
                              <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                              <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                            </g>
                            <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                          </g>
                        </svg>
                        <div className="text-white flex items-center  -mt-[0.3rem] sm:mt-0.5">
                          <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[2.8rem] w-[2.8rem] fill-[#07eceb8a]">
                            <path d="M3 7C3 5.11438 3 4.17157 3.58579 3.58579C4.17157 3 5.11438 3 7 3H12H17C18.8856 3 19.8284 3 20.4142 3.58579C21 4.17157 21 5.11438 21 7V10V13C21 14.8856 21 15.8284 20.4142 16.4142C19.8284 17 18.8856 17 17 17H12H7C5.11438 17 4.17157 17 3.58579 16.4142C3 15.8284 3 14.8856 3 13V10V7Z" stroke="#ffffff" strokeWidth={2} strokeLinejoin="round" />
                            <path d="M9 21L11.625 17.5V17.5C11.8125 17.25 12.1875 17.25 12.375 17.5V17.5L15 21" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 7L12 13" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 8L16 13" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 9L8 13" stroke="#ffffff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <div className="pr-5">
                          <p className="text-xl capitalize font-semibold pt-4">presentation</p>
                          <Link 
                          // to="https://defiworld.ai/download-materials?material_type=presentation"
                          >
                            <div className="flex space-x-2 text-gray-400 text-sm mt-1">
                              {/* svg  */}
                              <svg width={21} height={21} viewBox="0 0 24 24" fill="none" stroke="#8899a4" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5C8.24261 5 5.43602 7.4404 3.76737 9.43934C2.51521 10.9394 2.51521 13.0606 3.76737 14.5607C5.43602 16.5596 8.24261 19 12 19C15.7574 19 18.564 16.5596 20.2326 14.5607C21.4848 13.0606 21.4848 10.9394 20.2326 9.43934C18.564 7.4404 15.7574 5 12 5Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#50dbff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <p>View</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section></div>
      </div>
    </div>
    
    <Footer/>
    {/*  */}
    {/* Tailwind CDN CSS */}
    {/*  */}
  </div>

)
}
