const baseurl = process.env.REACT_APP_API_URL;

export const ApiPaths = {
  getAllGames: `/get_all_games`,
  login: `/login-with-dap`,
  register: `/register-with-dap`,
  getProfile: "/get_profile",
  paymentRequest: "/payment-request",
  getPaymentMethod: "/get-payment-method",
  getWallets: "/get_wallets",
  getPaymentTransaction: "/get-payment-transaction",
  getProviders: "/get_all_providers",
  getDocumentation: "/get_page",
  changePassword: "/update-password",
  withdraw: "/withdraw",
  getTeamSection: "/get_team_section",
  getRanks: "/get-ranks",
  placeOrder: "/place-order-with-dap",
  confirmOrder: "/confirm-order-with-dap",
  getLevelTeam: "/get_level_team",
  getWithdrawCondition:"/get_withdraw_condition",
  getPackages: "/get-all-package",
  getOrders: "/get_orders",
  getAllTeam: "/get_all_team",
  getTraders: "/get_user_team",
  // getRate: "/get-price"
  generationTree: "/get-genealogy-tree",
  updateTxHash:"/tx_hash"
};
